<template>
  <div>
    <div class="no-background container is-max-desktop">
      <div class="mx-4">
        <MemodioHero />
      </div>
    </div>
    <BackgroundTop />
    <div class="full-width-background">
      <div class="container is-max-desktop">
        <div class="mx-4 pt-6">
          <AppPreview />
        </div>
      </div>
    </div>
    <div class="full-width-background">
      <BackgroundBottom />
    </div>
    <div class="in-front container is-max-desktop">
      <div class="mx-4">
        <CallToAction />
        <TrustedQuote person="doron" />
        <MemodioSupporterRows />
      </div>
    </div>
  </div>
</template>

<script>
import MemodioHero from "~/components/MemodioHero.vue"
import BackgroundTop from "~/components/BackgroundTop.vue"
import BackgroundBottom from "~/components/BackgroundBottom.vue"
import AppPreview from "~/components/AppPreview.vue"
import CallToAction from "~/components/CallToAction.vue"
import MemodioSupporterRows from "~/components/MemodioSupporterRows.vue"
export default {
  name: "IndexMemodio",
  components: { MemodioHero, BackgroundTop, BackgroundBottom, AppPreview, CallToAction, MemodioSupporterRows },
}
</script>

<style lang="scss" scoped>
.in-front {
  z-index: 21;
}

.full-width-background {
  background-color: $fancy-background;
  width: 100%;
}
</style>
