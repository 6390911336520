<template>
  <section id="hero" class="hero-section">
    <div class="columns is-centered reverse-it">
      <div class="hero-image-mask column">
        <img
          src="~assets/images/landing/hero_keyvisual.webp"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 369px, 472.65625px"
          srcset="
            ~assets/images/landing/hero_keyvisual-p-500.webp 500w,
            ~assets/images/landing/hero_keyvisual.webp       694w
          "
          class="hero-image"
          :alt="$t('home.alt.keyvisual')"
        />
        <img alt="Ce badge" class="ce_badge" src="~assets/images/landing/ce_badge.webp" />
        <StoreContainer />
      </div>
      <div class="hero-description column has-text-primary">
        <h1 class="pb-32 is-size-2-mobile">
          {{ $t("home.dementiaPrevention") }}
          <br />
          {{ $t("home.viaApp") }}
        </h1>
        <p class="pb-32 pr-7 hero-text is-size-5 is-size-5-mobile">
          {{ $t("home.memodioHelpsPeople") }}
        </p>
        <p class="pb-32 pr-7 hero-text is-size-5 is-size-5-mobile">
          {{ $t("home.callToAction") }}
        </p>
        <a :href="appstoresForwardingUrl" target="_blank">
          <button class="button is-brandpurple is-rounded btn is-responsive">
            {{ $t("home.appstores_link") }}
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { APPSTORES_FORWARDING_URL } from "~/services/constants"
import StoreContainer from "~/components/utils/StoreContainer.vue"
import imageUrl from "~/assets/images/quiz/healthy.svg"

export default {
  name: "MemodioHero",
  components: { StoreContainer },
  data() {
    return {
      appstoresForwardingUrl: APPSTORES_FORWARDING_URL,
      imageUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.pb-32 {
  padding-bottom: 32px;
}

.hero-section {
  @media screen and (min-width: $tablet) {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  @media screen and (max-width: $tablet) {
    padding-top: 20px;
    padding-bottom: 64px;
  }
}

.hero-image-mask {
  @media screen and (max-width: $tablet) {
    justify-content: center;
  }
}
.hero-image {
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

.ce_badge {
  display: block;
  position: relative;
  width: 57%;
  margin: -75px auto 27px;
  @media screen and (min-width: $tablet) {
    display: block;
    position: relative;
    width: 57%;
    margin: -80px auto 24px;
  }
}

.store-container {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.store_emblem {
  max-height: 48px;
  min-height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  width: 136px;
}

.reverse-it {
  flex-direction: row-reverse;
}
</style>
