<template>
  <section id="feature-preview-section" class="feature-preview-section">
    <div class="preview-body columns is-centered reverse-it">
      <div class="column is-two-fifths p-7 has-text-centered">
        <div class="mockup-container">
          <div class="img-wrapper">
            <img
              class="mockup"
              src="~assets/images/landing/iphone_flat_emil.webp"
              :alt="$t('appPreview.images.phoneContent')"
            />
          </div>
          <img
            src="../assets/images/landing/exist-logos.webp"
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 368.953125px, 120%"
            srcset="
              ../assets/images/landing/exist-logos-p-500.webp  500w,
              ~/assets/images/landing/exist-logos-p-800.webp   800w,
              ~/assets/images/landing/exist-logos-p-1080.webp 1080w,
              ~/assets/images/landing/exist-logos-p-1600.webp 1600w,
              ~/assets/images/landing/exist-logos-p-2000.webp 2000w,
              ~/assets/images/landing/exist-logos-p-2600.webp 2600w,
              ~/assets/images/landing/exist-logos.webp        2785w
            "
            :alt="$t('appPreview.alt.existLogos')"
          />
        </div>
      </div>
      <div class="column info mx-4">
        <h2 class="is-size-3 is-size-3-mobile pb-6">
          {{ $t("appPreview.featureTitle") }}
        </h2>
        <p class="is-size-5 is-size-5-mobile pb-40">
          {{ $t("appPreview.appointmentSubtitle") }}
        </p>
        <ul class="feature-list is-size-5 is-size-5-mobile pt-4">
          <li>
            <img
              class="module-icon"
              :alt="$t('appPreview.images.modules')"
              src="~assets/images/preview_icons/interactive_modules.svg"
            />
            <div>{{ $t("appPreview.functions.modules") }}</div>
          </li>
          <li>
            <img
              class="module-icon"
              :alt="$t('appPreview.images.plan')"
              src="~assets/images/preview_icons/individualized_intervention_plan.svg"
            />
            <div>{{ $t("appPreview.functions.plan") }}</div>
          </li>
          <li>
            <img
              class="module-icon"
              :alt="$t('appPreview.images.dataprotection')"
              src="~assets/images/preview_icons/data_protection.svg"
            />
            <div>{{ $t("appPreview.functions.dataprotection") }}</div>
          </li>
        </ul>
        <a :href="appstoresForwardingUrl" target="_blank">
          <button class="button is-rounded is-brandpurple is-responsive">
            {{ $t("appPreview.buttonAppstoresLink") }}
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { APPSTORES_FORWARDING_URL } from "~/services/constants"
export default {
  name: "AppPreview",
  data() {
    return {
      appstoresForwardingUrl: APPSTORES_FORWARDING_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
.pb-40 {
  padding-bottom: 24px;
}

.module-icon {
  width: 40px;
  height: 40px;
}

.feature-preview-section {
  h2 {
    margin-top: 0;
    margin-bottom: -32px;
    @media screen and (min-width: $tablet) {
      margin-top: 8px;
    }
    @media screen and (max-width: $tablet) {
      margin-top: 32px;
    }
  }
  @media screen and (min-width: $tablet) {
    height: 680px;
    padding-bottom: 80px;
    margin-top: -160px;
  }
  @media screen and (max-width: $tablet) {
    padding-bottom: 96px;
    margin-top: 0;
  }
  background-color: $fancy-background;
}
.info {
  padding-left: 64px;
  @media screen and (max-width: $tablet) {
    padding-left: 10px;
  }
}
.preview-body {
  @media screen and (min-width: $tablet) {
    margin-top: 120px;
  }
  @media screen and (max-width: $tablet) {
    margin-top: 0;
  }
}
.feature-list {
  margin-bottom: 16px;
}
.feature-list li {
  display: flex;
  align-items: center;
  width: 85%;
  padding-bottom: 24px;
  font-weight: 500;
  img {
    margin-right: 32px;
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}
.reverse-it {
  flex-direction: row;
}
.store-container {
  display: flex;
  justify-content: space-evenly;
}
.store_emblem {
  max-height: 40px;
  min-height: 40px;
  width: 100%;
}
.mockup-container {
  // margin-top: -128px;
  margin-left: 0px;
  width: 100%;
  background-color: white;
  padding: 40px 16px 0 16px;
  border-radius: 40px;
  box-shadow: 2px 18px 28px rgba(8, 31, 48, 0.25);
  @media screen and (max-width: $tablet) {
    margin-left: 40px;
  }
  @media screen and (max-width: $tablet) {
    margin-left: 0;
  }
}
.img-wrapper {
  padding: 0 40px 0 40px;
}
.mockup {
  margin-top: -80px;
}
</style>
