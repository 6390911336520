<template>
  <section id="callToAction" class="cta-section">
    <LogoDivider title="" class="logoDivider" />
    <div class="columns is-centered">
      <div class="column feature-image-column">
        <img
          src="~assets/images/landing/relatives.webp"
          :alt="$t('cta.alt.relatives')"
          class="feature-image p-2"
          quality="100"
        />
      </div>
      <div class="column has-text-primary">
        <div class="cta-text">
          <h2 class="is-size-3 is-size-3-mobile pb-5">
            {{ $t("cta.imageTitle") }}
          </h2>
          <p class="is-size-5 is-size-5-mobile">
            {{ $t("cta.imageText") }}
          </p>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered has-text-left-mobile">
        <div class="cta-buttons">
          <a :href="appstoresForwardingUrl" target="_blank">
            <button class="button is-brandpurple is-rounded is-responsive margin-button-top">
              {{ $t("cta.buttonAppstoresLink") }}
            </button>
          </a>

          <a
            href="#feature-preview-section"
            class="button is-brandpurple is-rounded is-responsive is-outlined margin-button-bottom"
          >
            {{ $t("cta.buttonMoreInfo") }}
          </a>
        </div>
      </div>
      <div class="column is-half has-text-primary">
        <h2 class="is-size-3 is-size-3-mobile pb-5">{{ $t("cta.buttonTitle") }}</h2>
        <p class="is-size-5 is-size-5-mobile">{{ $t("cta.buttonText") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import LogoDivider from "~/components/LogoDivider.vue"
import { APPSTORES_FORWARDING_URL } from "~/services/constants"
export default {
  components: { LogoDivider },
  data() {
    return {
      appstoresForwardingUrl: APPSTORES_FORWARDING_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-button-top {
  margin: 0 16px 16px 16px;

  @media screen and (max-width: $mobile) {
    margin: 8px 0 24px 0;
  }
}

.margin-button-bottom {
  @media screen and (max-width: $mobile) {
    margin: 0 0 8px 0;
  }
}

#callToAction {
  background-color: white;
  padding-bottom: 96px;
  margin-top: 32px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 120px;
  }
}
.cta-buttons {
  width: 60%;
  justify-content: center;
  margin-left: 48px;
  margin-bottom: 40px;

  @media screen and (max-width: $mobile) {
    justify-content: left;
    margin-left: 0;
  }
}
.feature-image-column {
  @media screen and (max-width: $mobile) {
    display: flex;
    justify-content: center;
  }
}

.feature-image {
  max-width: 400px;
  @media screen and (max-width: $mobile) {
    max-width: 360px;
    padding: 0 50%;
    margin-top: -24px;
  }
}

.cta-text {
  @media screen and (min-width: $tablet) {
    padding-top: 104px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: $tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.logoDivider {
  padding-bottom: 64px;
}
</style>
